import React from 'react'
import VenueTemplate from '../templates/VenueTemplate'
import SubmitBarcode from '../venue/barcodes/SubmitBarcode'

const VenueBarcodeScreen = (props) => {
    return(
        <VenueTemplate>
            <h1>Barcodes</h1>
            <SubmitBarcode />
        </VenueTemplate>
    )
}

export default VenueBarcodeScreen