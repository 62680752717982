import React, { useState } from 'react'
import EditSubmission from './EditSubmission'
import { Button, ButtonGroup } from 'react-bootstrap'

const SubmissionList = (props) => {

    const [currentlyEditing, setCurrentlyEditing] = useState(null)

    const handleSetCurrentlyEditing = (submission) => {
        if( currentlyEditing !== null && currentlyEditing.type === submission.type )
            return setCurrentlyEditing(null)

        setCurrentlyEditing(submission)
    }


    const handleAfterSave = () => {
        props.handleAfterUpdate()
        setCurrentlyEditing(null)
    }

    return (
        <div className="submissionList">
            {
                props.data && props.data.length > 0 ?
                    <ButtonGroup aria-label="submissionButtons">
                    {
                        props.data.map( (submission, index) => {
                            return <Button key={`submissionButton${index}`} onClick={() => handleSetCurrentlyEditing(submission)} variant="primary">
                                { submission.type === 'mailout' ? 'Mailout'  : null }
                                { submission.type === 'bday'    ? 'Birthday' : null }
                                { submission.type === 'email'   ? 'Email'    : null }
                            </Button>
                        })
                    }
                    </ButtonGroup>
                : null
            }

            {
                currentlyEditing !== null ?
                    <EditSubmission submission={currentlyEditing} handleAfterUpdate={handleAfterSave} />
                : null
            }
        </div>
    )
}

export default SubmissionList
