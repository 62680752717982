import React from 'react'
import AdminSidebar from '../sidebar/AdminSidebar'
import { Container, Col, Row } from 'react-bootstrap'
import Header from './Header'
import SessionChecker from '../utilities/SessionChecker'

const AdminTemplate = (props) => {
    return (
        <Container fluid>
            <Header />
            <Row>
                <Col md={3}>
                    <AdminSidebar />
                </Col>
                <Col md={7}>
                    <div className="adminTemplate">
                        { props.children }
                    </div>
                </Col>
            </Row>
            <SessionChecker />
        </Container>
    )
}

export default AdminTemplate