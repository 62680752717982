import React from 'react'
import { Row, Col } from 'react-bootstrap'
import UserMetaDisplay from '../user/UserMetaDisplay'
import LogoutButton from '../logout/LogoutButton'

const Header = (props) => {

    return (
        <Row className="mb-5">
            <Col md={3} className="p-3">
                <img src="/logo.png" className="logo" />
            </Col>
            <Col md={6}>

            </Col>
            <Col md={3} className="pageMeta p-3">
                <UserMetaDisplay />
                <LogoutButton />
            </Col>
        </Row>
    )
}

export default Header