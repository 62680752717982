import React from 'react'
import AdminTemplate from '../templates/AdminTemplate'

const AdminScreen = (props) => {
    return(
        <AdminTemplate>
            <p>Welcome, O Great Administrator</p>
        </AdminTemplate>
    )
}

export default AdminScreen