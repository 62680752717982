import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { doLogout } from '../../services/siteService'

const LogoutButton = (props) => {

    const history                 = useHistory()

    const startLogout = () => {
        doLogout(history)
    }


    return (
        <Button variant="primary" size="sm" onClick={startLogout}>Logout</Button>
    )
}

export default LogoutButton