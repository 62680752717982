import React, { useState, useEffect } from 'react'
import { getDueSubmissions } from '../../services/apiService'
import VenueTemplate from '../templates/VenueTemplate'
import SubmissionList from '../venue/submissions/SubmissionList'
import CreateSubmission from '../venue/submissions/CreateSubmission'
import EditSubmission from '../venue/submissions/EditSubmission'

const VenueSubmissionScreen = (props) => {

    const [ dueSubmissions, setDueSubmissions ] = useState(null)


    const checkDueSubmissions = () => {
        getDueSubmissions().then( response => {
            setDueSubmissions(response.data)
        }, error => {
            console.log(error)
        })
    }


    const handleAfterUpdate = () => {
        getDueSubmissions().then( response => {
            setDueSubmissions(response.data)
            
        }, error => {
            console.log(error)
        })
    }


    useEffect(() => {

        if( dueSubmissions !== null )
            return

        checkDueSubmissions()

    }, [])

    return(
        <VenueTemplate>
            <h1>Submissions</h1>
            {
                dueSubmissions !== null && Array.isArray(dueSubmissions) && dueSubmissions.length === 0 ?
                    <p>No Current Submissions Due</p>
                : <SubmissionList data={dueSubmissions} handleAfterUpdate={handleAfterUpdate} />
            }
            
           {/*  <CreateSubmission />
            <EditSubmission /> */}
        </VenueTemplate>
    )
}

export default VenueSubmissionScreen