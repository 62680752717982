import axios from 'axios'
import config from '../config/urls'


export const loginUser = (username, password) => {
    return axios.post(`${config.apiBaseURL}/user/login`, {
        username,
        password
    })
}

export const getDueSubmissions = () => {
    const token = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/mailout/getDueSubmissions`, {
        headers: {
            authorization: token
        }
    })
}


export const saveSubmission = (mailout) => {
    const token = localStorage.getItem('token')

    return axios.post(`${config.apiBaseURL}/mailout/submitMailout`, {
        mailout
    }, {
        headers: {
            authorization: token
        }
    })
}



export const savePatron = (playerData) => {
    const token     = localStorage.getItem('token')

    return axios.put(`${config.apiBaseURL}/vendb/addPlayer`, {
        playerData,
    }, {
        headers: {
            authorization: token
        }
    })
}

export const editPatron = (playerData) => {
    const token     = localStorage.getItem('token')

    return axios.put(`${config.apiBaseURL}/vendb/editPlayer`, {
        playerData,
    }, {
        headers: {
            authorization: token
        }
    })
}



export const getGenericMailoutTemplate = () => {
    const token     = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/mailout/getMailTemplate`,{
        headers: {
            authorization: token
        }
    })
}

export const getGenericEmailTemplate = () => {
    const token     = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/mailout/getEmailTemplate`,{
        headers: {
            authorization: token
        }
    })
}

export const getGenericBirthdayTemplate = () => {
    const token     = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/mailout/getBdayTemplate`,{
        headers: {
            authorization: token
        }
    })
}


export const getAdminSubmissionList = () => {
    const token     = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/mailout/getAllTemplates`,{
        headers: {
            authorization: token
        }
    })
}




export const saveSubmissionTemplate = (mailout) => {
    const token = localStorage.getItem('token')

    return axios.post(`${config.apiBaseURL}/mailout/saveTemplate`, {
        mailout
    }, {
        headers: {
            authorization: token
        }
    })
}


export const editSubmissionTemplate = (mailout) => {
    const token = localStorage.getItem('token')

    return axios.post(`${config.apiBaseURL}/mailout/editTemplate`, {
        mailout
    }, {
        headers: {
            authorization: token
        }
    })
}


export const saveBarcode = (barcodeText, venueID) => {
    const token = localStorage.getItem('token')

    return axios.put(`${config.apiBaseURL}/vendb/addVisitBarcode`, {
        uploadData: {
            venueID,
            barcodeText
        }
    }, {
        headers: {
            authorization: token
        }
    })
}





export const checkSession = () => {
    const token = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/user/checksession`, {
        headers: {
            authorization: token
        }
    })
}



export const getPatrons = () => {
    const token = localStorage.getItem('token')

    return axios.get(`${config.apiBaseURL}/vendb/getPlayers`, {
        headers: {
            authorization: token
        }
    })
}


