import moment from 'moment'


export const doLogout = (history) => {
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('user')
    localStorage.removeItem('token')

    history.replace('/')
}



export const tidyTemplateDates = (data) => {
    return Object.assign({}, data, {
        date:       moment(data.date,       'DD/MM/YYYY').format('YYYY-MM-DD'),
        start_date: moment(data.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date:   moment(data.end_date,   'DD/MM/YYYY').format('YYYY-MM-DD')
    })
}