import React from 'react'
import { Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

const AdminSidebar = (props) => {
    return (
        <div className="sidebar adminSidebar">
            <ListGroup>
                <ListGroup.Item>
                    <Link to="/admin/templates">Templates</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/admin/users">Users</Link>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default AdminSidebar