import React from 'react'
import AdminTemplate from '../templates/AdminTemplate'

const AdminUserScreen = (props) => {
    return(
        <AdminTemplate>
            <p>User Administration</p>
        </AdminTemplate>
    )
}

export default AdminUserScreen