import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

const MailoutSubmissionForm = (props) => {

    const [ submissionData, setSubmissionData ] = useState(props.data)
    const [ user, setUser ]                       = useState(JSON.parse(localStorage.getItem('user')))

    const handleChange = (e) => {
        setSubmissionData(Object.assign({}, submissionData, {
            [e.target.name]: e.target.value
        }))
    }


    return (
        <div className="submissionForm mb-5">
            <h2>Letter / Mailout</h2>
            <Row>
                <Col md={12}>

                    <div className="form-group mb-5">
                        <label htmlFor="Intro Page">Intro Page</label>
                        <textarea className="form-control larger" onChange={handleChange} name="text1" value={submissionData.text1} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 1">Offer 1</label>
                        <textarea className="form-control" onChange={handleChange} name="text2" value={submissionData.text2} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 2">Offer 2</label>
                        <textarea className="form-control" onChange={handleChange} name="text3" value={submissionData.text3} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 3">Offer 3</label>
                        <textarea className="form-control" onChange={handleChange} name="text4" value={submissionData.text4} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 4">Offer 4</label>
                        <textarea className="form-control" onChange={handleChange} name="text5" value={submissionData.text5} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Venue Text">Venue Designated Text</label>
                        <textarea className="form-control" onChange={handleChange} name="text6" value={submissionData.text6} />
                    </div>
                    
                    {
                        user.venue_type === 'me' ?
                            <div className="form-group mb-5">
                                <h5>Minimum Recent Visits</h5>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline0" name="filters" className="custom-control-input" onChange={handleChange} value={0} checked={parseInt(submissionData.filters)  === 0 } />
                                    <label className="custom-control-label" htmlFor="customRadioInline0">0 (All Patrons)</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="filters" className="custom-control-input" onChange={handleChange} value={1} checked={parseInt(submissionData.filters)  === 1 } />
                                    <label className="custom-control-label" htmlFor="customRadioInline1">1+ Visits</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="filters" className="custom-control-input" onChange={handleChange} value={2} checked={parseInt(submissionData.filters)  === 2 } />
                                    <label className="custom-control-label" htmlFor="customRadioInline2">2+ Visits</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline3" name="filters" className="custom-control-input" onChange={handleChange} value={3} checked={parseInt(submissionData.filters)  === 3 } />
                                    <label className="custom-control-label" htmlFor="customRadioInline3">3+ Visits</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline4" name="filters" className="custom-control-input" onChange={handleChange} value={4} checked={parseInt(submissionData.filters)  === 4 } />
                                    <label className="custom-control-label" htmlFor="customRadioInline4">4+ Visits</label>
                                </div>
                            </div>
                        :null 
                    }
                    

                    <Button onClick={() => props.handleSave(submissionData)}>Save</Button>
                    
                </Col>
            </Row>
        </div>
    )
}

export default MailoutSubmissionForm
