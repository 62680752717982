import React, { useState } from 'react'

const SubmitBarcode = (props) => {

    const [barcode, setBarcode] = useState('')

    const handleBarcodeEntry = (e) => {
        if( e.target.value.length === 17 ){
            console.log('Sending off barcode')
        }

        setBarcode(e.target.value)
    }


    return (
        <div className="submitBarcode">
           <p>Submit Barcodes, one at a time</p>
           <input name="barcode" onChange={handleBarcodeEntry} placeholder="Enter Barcode" value={barcode} className="form-control" />
        </div>
    )
}

export default SubmitBarcode
