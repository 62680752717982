import React, { useState }      from 'react'
import { Button }               from 'react-bootstrap'
import CreateEmailSubmission    from './CreateEmailSubmission'
import CreateMailoutSubmission  from './CreateMailoutSubmission'
import CreateBirthdaySubmission from './CreateBirthdaySubmission'

const EditSubmissionTemplate = props => {
    return (
        <div className="editSubmissionTemplate">
            <Button onClick={props.handleCancel} className="float-right" variant="dark" size="sm">Close</Button>
            { props.template.type === 'email'   ? <CreateEmailSubmission    template={props.template} type="edit" /> : null }
            { props.template.type === 'mailout' ? <CreateMailoutSubmission  template={props.template} type="edit" /> : null }
            { props.template.type === 'bday'    ? <CreateBirthdaySubmission template={props.template} type="edit" /> : null }
        </div>
    )
}

export default EditSubmissionTemplate