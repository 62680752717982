import React from 'react'
import VenueSidebar from '../sidebar/VenueSidebar'
import { Container, Col, Row } from 'react-bootstrap'
import Header from './Header'
import SessionChecker from '../utilities/SessionChecker'

const VenueTemplate = (props) => {
    return (
        <Container fluid>
            <Header />
            <Row>
                <Col md={3}>
                    <VenueSidebar />
                </Col>
                <Col md={7}>
                    <div className="venueTemplate">
                        { props.children }
                    </div>
                </Col>
            </Row>
            <SessionChecker />
        </Container>
    )
}

export default VenueTemplate