import React, { useState, useEffect } from 'react'
import { Redirect,
  useHistory,
} from "react-router-dom"
import { Container, Row, Col, Button } from 'react-bootstrap'
import { loginUser } from '../../services/apiService'
import { show } from '../../services/showMessage'

// This page doubles as the homescreen, it is access via the '/' route
// It's job is to redirect
const LoginGateScreen = (props) => {


    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const history                 = useHistory()

    const startLogin = (e) => {

        e.preventDefault()

        if( username === '' || password === '' )
            return show('Please enter a username and password')


        loginUser(username, password).then( response => {
            const user = response.data.result

            localStorage.setItem('loggedIn', true)
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('token', response.data.token)

            history.replace(`/${user.user_type}`)
        }, error => {
            console.log(error)
            show('Incorrect login. Please try again or contact PVS.')
        })

        
    }


    return (
        <Container>
            <div className="loginForm">
                <form onSubmit={startLogin}>
                <Row>
                    <Col md={12}>
                        <Row className="mb-4">
                            <Col md={12}>
                                <img src="logo.png" className="frontLogo" />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <input className="form-control" name="username" value={username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <input className="form-control" name="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Button type="submit" variant="primary" size="sm" onClick={startLogin}>Login</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </form>
            </div>
        </Container>
    )
}

export default LoginGateScreen