import moment from 'moment'

const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateAddPatron = (data) => {

    let errors = []

    if( data.birthday !== '' && !moment(data.birthday, 'DD/MM/YYYY').isValid() )
        errors.push({ field: 'birthday', error: 'Invalid Date'})

    if( data.first_name === '' )
        errors.push({ field: 'first_name', error: 'First name is required'})
    
    if( data.last_name === '' )
        errors.push({ field: 'last_name', error: 'Last name is required'})

    if( data.address === '' )
        errors.push({ field: 'address', error: 'Address is required'})

    if( data.state === '' )
        errors.push({ field: 'state', error: 'State is required'})

    if( data.postcode === '' )
        errors.push({ field: 'postcode', error: 'Postcode is required'})


    if( data.suburb === '' )
        errors.push({ field: 'suburb', error: 'Suburb is required'})


    if( data.email !== '' && ! regexEmail.test(data.email) )
        errors.push({ field: 'email', error: 'Email address is invalid'})

    return errors
}


