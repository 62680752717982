import React from 'react'
import { Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

const VenueSidebar = (props) => {
    return (
        <div className="sidebar venueSidebar">
            <ListGroup>
                <ListGroup.Item>
                    <Link to="/venue/submissions">Submissions</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/venue/patrons">Patrons</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/venue/barcodes">Barcodes</Link>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default VenueSidebar