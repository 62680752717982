import React, { useState, useEffect } from 'react'
import VenueTemplate from '../templates/VenueTemplate'
import PatronList from '../venue/patrons/PatronList'
import CreatePatron from '../venue/patrons/CreatePatron'
import EditPatron from '../venue/patrons/EditPatron'
import { getPatrons } from '../../services/apiService'
import { Row, Col } from 'react-bootstrap'



const VenuePatronScreen = (props) => {

    const [patronList, setPatronList] = useState(null)
    const [currentlyEditing, setCurrentlyEditing] = useState(null)

    useEffect(()=> {

        if( patronList !== null )
            return 

        getPatronList()

    }, [])

    const getPatronList = () => {
        getPatrons().then( response => {
            setPatronList(response.data)
        })
    }



    const handleEditPatron = (patron) => {
        if( currentlyEditing !== null && patron !== null && currentlyEditing.player_id === patron.player_id )
            setCurrentlyEditing(null)
        else    
            setCurrentlyEditing(patron)
    }



    return (
        <VenueTemplate >
            <h1>Patrons</h1>
            <Row>
                <Col md={6}>
                {
                    patronList !== null ?
                        <PatronList patrons={patronList} handleEditPatron={handleEditPatron} currentlyEditing={currentlyEditing} />
                    : null
                }
                </Col>
                <Col md={6}>
                {
                    currentlyEditing === null ?
                        <CreatePatron getPatronList={getPatronList} />
                    : <EditPatron key={currentlyEditing.player_id} patron={currentlyEditing} getPatronList={getPatronList} handleEditPatron={handleEditPatron} />
                }
                </Col>
            </Row>
        </VenueTemplate>
    )
}


export default VenuePatronScreen