import VenueScreen           from '../components/pages/VenueScreen'
import VenueSubmissionScreen from '../components/pages/VenueSubmissionScreen'
import VenuePatronScreen     from '../components/pages/VenuePatronScreen'
import VenueBarcodeScreen    from '../components/pages/VenueBarcodeScreen'
import LoginGateScreen       from '../components/pages/LoginGateScreen'
import AdminScreen           from '../components/pages/AdminScreen'
import AdminTemplateScreen   from '../components/pages/AdminTemplateScreen'
import AdminUserScreen       from '../components/pages/AdminUserScreen'

const routes = [
    {
        path:      '/',
        component: LoginGateScreen,
        exact:     true,
        private:   false
    },
    {
        path:      '/venue',
        component: VenueScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/venue/submissions',
        component: VenueSubmissionScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/venue/patrons',
        component: VenuePatronScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/venue/barcodes',
        component: VenueBarcodeScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/admin',
        component: AdminScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/admin/templates',
        component: AdminTemplateScreen,
        exact:     true,
        private:   true
    },
    {
        path:      '/admin/users',
        component: AdminUserScreen,
        exact:     true,
        private:   true
    },
]

export default routes