import React, { useState } from 'react'
import PatronForm from './PatronForm'
import { show } from '../../../services/showMessage'
import { editPatron } from '../../../services/apiService'
import moment from 'moment'
 
const EditPatron = (props) => {

    const [ patron, setPatron ] = useState(Object.assign({}, props.patron, {
        birthday: moment(props.patron.birthday, 'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY')
    }))

    const handleSavePatron = (data) => {
        editPatron(Object.assign({}, data, {
            birthday: moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
        })).then( response => {
            show('Patron Edited')
            // Clear form,
            props.handleEditPatron(null)
            // Update list of patrons
            props.getPatronList()
        }, error => {
            show('Unable to Edit')
        })
    }


    const handleChange = (e) => {
        setPatron(Object.assign({}, patron, {
            [e.target.name]: e.target.value
        }))
    }

    const handleBooleanChange = (e) => {
        setPatron(Object.assign({}, patron, {
            [e.target.name]: !patron[e.target.name]
        }))
    }

    return (
        <div className="editSubmission">
            <h4>Edit Patron</h4>
            <PatronForm 
                handleSavePatron={handleSavePatron} 
                handleChange={handleChange}
                handleBooleanChange={handleBooleanChange}
                data={patron} />
        </div>
    )
}

export default EditPatron
