import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={ (props) => (
            localStorage.getItem('loggedIn') === 'true'
                ? <Component {...props} />
                : <Redirect to='/' />
        )} />
    )
}

export default PrivateRoute