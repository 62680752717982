import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import moment  from 'moment'

const EmailSubmissionForm = (props) => {

    

    const [ submissionData, setSubmissionData ] = useState(Object.assign({}, props.data, {
        date:       moment(props.data.date,       'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY'),
        start_date: moment(props.data.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY'),
        end_date:   moment(props.data.end_date,   'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY')
    }))


    const handleChange = (e) => {
        setSubmissionData(Object.assign({}, submissionData, {
            [e.target.name]: e.target.value
        }))
    }


    return (
        <div className="submissionForm mb-5">
            <Row>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="month">Month</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="date" value={submissionData.date} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="startDate">Start Date</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="start_date" value={submissionData.start_date} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="endDate">End Date</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="end_date" value={submissionData.end_date} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>

                    <div className="form-group mb-5">
                        <label htmlFor="Email Content">Content</label>
                        <textarea className="form-control larger" onChange={handleChange} name="text1" value={submissionData.text1} />
                    </div>

                    <Button onClick={() => props.handleSave(submissionData)}>Save</Button>


                </Col>
            </Row>
        </div>
    )
}

export default EmailSubmissionForm
