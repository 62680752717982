import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

const EmailSubmissionForm = (props) => {

    const [ submissionData, setSubmissionData ] = useState(props.data)

    const handleChange = (e) => {
        setSubmissionData(Object.assign({}, submissionData, {
            [e.target.name]: e.target.value
        }))
    }


    return (
        <div className="submissionForm mb-5">
            <h2>Email</h2>
            <Row>
                <Col md={12}>

                    <div className="form-group mb-5">
                        <label htmlFor="Email Content">Content</label>
                        <textarea className="form-control larger" onChange={handleChange} name="text1" value={submissionData.text1} />
                    </div>

                    <Button onClick={() => props.handleSave(submissionData)}>Save</Button>


                </Col>
            </Row>
        </div>
    )
}

export default EmailSubmissionForm
