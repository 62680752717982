import React, { useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import MaskedInput       from 'react-maskedinput'

const PatronForm = (props) => {


    return (
        <div className="patronForm mb-5">
            <form onSubmit={() => props.handleSavePatron(props.data)}>
            <Row>
                <Col md={6}>

                    <div className="form-group mb-5">
                        <label htmlFor="first_name">First Name</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="first_name" value={props.data.first_name} />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group mb-5">
                        <label htmlFor="last_name">Last Name</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="last_name" value={props.data.last_name} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>

                    <div className="form-group mb-5">
                        <label htmlFor="birthday">Birthday</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={props.handleChange} name="birthday" value={props.data.birthday} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="email">Email</label>
                        <input className="form-control" onChange={props.handleChange} name="email" value={props.data.email} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="mobile">Mobile</label>
                        <input className="form-control" onChange={props.handleChange} name="mobile" value={props.data.mobile} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="form-group mb-5">
                        <label htmlFor="address">Address</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="address" value={props.data.address} />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group mb-5">
                        <label htmlFor="suburb">Suburb</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="suburb" value={props.data.suburb} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="form-group mb-5">
                        <label htmlFor="state">State</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="state" value={props.data.state} />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group mb-5">
                        <label htmlFor="postcode">Postcode</label> <small className="text-muted">Required</small>
                        <input className="form-control" onChange={props.handleChange} name="postcode" value={props.data.postcode} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="custom-control custom-checkbox mb-5">
                        <label htmlFor="status">Active</label>
                        <input type="checkbox" className="form-control " onChange={props.handleBooleanChange} name="status" value={props.data.status} checked={props.data.status} />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="custom-control custom-checkbox mb-5">
                        <label htmlFor="mail_okay">Mail Ok?</label>
                        <input type="checkbox" className="form-control " onChange={props.handleBooleanChange} name="mail_okay" value={props.data.status} checked={props.data.mail_okay} />
                    </div>
                </Col>
            </Row>
            <Button onClick={() => props.handleSavePatron(props.data)}>Save</Button>
            </form>
        </div>
    )
}

export default PatronForm
