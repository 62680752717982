import React, { useState } from 'react'

const UserMetaDisplay = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))

    return (
        <span className="nameBox mr-4">{user.name}</span>
    )
}

export default UserMetaDisplay