import React, { useState, useEffect } from 'react'
import { getAdminSubmissionList }     from '../../../services/apiService'
import { Table, Button }              from 'react-bootstrap'
import moment                         from 'moment'
import EditSubmissionTemplate         from './EditSubmissionTemplate'

const SubmissionList = (props) => {


    const [submissions, setSubmissions]           = useState(null)
    const [currentlyEditing, setCurrentlyEditing] = useState(null)


    useEffect(()=> {

        if( submissions !== null )
            return

        getAdminSubmissionList().then( response => {
            setSubmissions(response.data)
        }, error => {
            console.log('Couldnt get admin submission templates')
        })

    }, [])


    const handleEditTemplate = (template) => {
        setCurrentlyEditing(template)
    }


    const handleCancel = () => {
        setCurrentlyEditing(null)
    }



    if( currentlyEditing )
        return <EditSubmissionTemplate template={currentlyEditing} handleCancel={handleCancel} />



    return (
        <div className="adminSubmissionList">
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    typeof submissions !== 'undefined' && submissions !== null && submissions.map( (submission, index) => {
                        return (
                            <tr key={`submissionsList${index}`}>
                                <td>{moment(submission.date, 'YYYY-MM-DDTHH:mm:ss.000Z').format('MMM YYYY')}</td>
                                <td>{submission.type.charAt(0).toUpperCase() + submission.type.slice(1)}</td>
                                <td>{moment(submission.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z').format('DD MMM YYYY')}</td>
                                <td>{moment(submission.end_date,  'YYYY-MM-DDTHH:mm:ss.000Z').format('DD MMM YYYY')}</td>
                                <td><Button size="sm" onClick={()=> handleEditTemplate(submission)}>Edit</Button></td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>                
        </div>
    )
}

export default SubmissionList