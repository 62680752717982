import React from 'react'
import BirthdaySubmissionForm from './BirthdaySubmissionForm'
import MailoutSubmissionForm  from './MailoutSubmissionForm'
import EmailSubmissionForm    from './EmailSubmissionForm'
import { saveSubmission }     from '../../../services/apiService'
import { show }               from '../../../services/showMessage'

const EditSubmission = (props) => {


    const handleSubmissionSave = (data) => {
        //console.log(data, 'saving submission data')
        saveSubmission(data).then( response => {
            show('Submission Saved')
            props.handleAfterUpdate()
        }, error => {
            console.log(error)
            show('Couldnt Save Submission')
        })
    }


    return (
        <div className="editSubmission mt-5">

            { props.submission.type === 'mailout' ? <MailoutSubmissionForm handleSave={handleSubmissionSave} data={props.submission} />  : null }
            { props.submission.type === 'bday'    ? <BirthdaySubmissionForm handleSave={handleSubmissionSave} data={props.submission} /> : null }
            { props.submission.type === 'email'   ? <EmailSubmissionForm  handleSave={handleSubmissionSave} data={props.submission} />   : null }

        </div>
    )
}

export default EditSubmission
