import React, { useState, useEffect } from 'react'
import { getGenericMailoutTemplate, saveSubmissionTemplate, editSubmissionTemplate } from '../../../services/apiService'
import { show } from '../../../services/showMessage'
import {tidyTemplateDates } from '../../../services/siteService'
import MailoutSubmissionForm from './MailoutSubmissionForm'

const CreateMailoutSubmission = (props) => {

    const [template, setTemplate] = useState(props.template || null)


    useEffect(() => {

        if( template === null ){
            getGenericMailoutTemplate().then( response => {
                setTemplate(response.data)
            }, error => {
                show('Unable to get base template')
            })
        }

    }, [])



    const handleSubmissionSave = (data) => {
        let callback = typeof props.type === 'undefined' || props.type !== 'edit' ? saveSubmissionTemplate : editSubmissionTemplate 
        callback(tidyTemplateDates(data)).then( response => {
            //console.log(response)
            show('Template Saved')
        }, error => {
            console.log(error)
            show('Couldnt save template')
        })
    }
    



    return (
        <div className="submissionForm">
            <h4>Mailout Submission Form</h4>
            {
                template !== null ? 
                    <MailoutSubmissionForm data={template} handleSave={handleSubmissionSave} />
                : null
            }
        </div>
    )
}

export default CreateMailoutSubmission