import React, { useState }     from 'react'
import AdminTemplate           from '../templates/AdminTemplate'
import { Button, ButtonGroup } from 'react-bootstrap'

import CreateBirthdaySubmission from '../admin/templates/CreateBirthdaySubmission'
import CreateEmailSubmission    from '../admin/templates/CreateEmailSubmission'
import CreateMailoutSubmission  from '../admin/templates/CreateMailoutSubmission'
import SubmissionList           from '../admin/templates/SubmissionList'


const AdminTemplateScreen = (props) => {

    const [ showForm, setShowForm ] = useState(null)




    return(
        <AdminTemplate>
            <h1>Templates</h1>
            <ButtonGroup aria-label="submissionButtons" className="mb-5">
                <Button variant="primary" onClick={() => setShowForm(showForm === 'mailout'  ? null : 'mailout')}>Mailout</Button>
                <Button variant="primary" onClick={() => setShowForm(showForm === 'birthday' ? null : 'birthday')}>Birthday</Button>
                <Button variant="primary" onClick={() => setShowForm(showForm === 'email'    ? null : 'email')}>Email</Button>
            </ButtonGroup>

            { showForm === 'mailout'  ? <CreateMailoutSubmission />  : null }
            { showForm === 'birthday' ? <CreateBirthdaySubmission /> : null}
            { showForm === 'email'    ? <CreateEmailSubmission />    : null}

            { showForm === null ? <SubmissionList /> : null }

        </AdminTemplate>
    )
}

export default AdminTemplateScreen