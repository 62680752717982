import React            from 'react'
import { useHistory }   from 'react-router-dom'
import { checkSession } from '../../services/apiService'
import { doLogout }     from '../../services/siteService'

const SessionChecker = (props) => {

    const history                = useHistory()

    const timer = setInterval(() => {
        checkSession().then( response => {
            
        }, error => {
            clearInterval(timer)
            doLogout(history)
        })
    }, 30000);

    return ''
}

export default SessionChecker