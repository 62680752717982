import React from 'react'
import VenueTemplate from '../templates/VenueTemplate'

const VenueScreen = (props) => {
    return(
        <VenueTemplate>
            <p>Welcome to the PVS Partner Portal.</p>
        </VenueTemplate>
    )
}

export default VenueScreen