import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Badge } from 'react-bootstrap'

const PatronList = (props) => {

    const [searchValue, setSearchValue]         = useState('')
    const [hideInactiveStatus,  setHideInactive]      = useState(true)
    const [filteredPatrons, setFilteredPatrons] = useState([])

 
    useEffect(() => {

        if( props.patrons === null )
            return

        let filtered = filterPatrons(props.patrons, '', true)
        //console.log(filtered)
        setFilteredPatrons(filtered)

    }, [props.patrons])



    const filterPatrons = (patrons, value, hideInactive) => {
        return patrons.filter( patron => {
            let returnValue  = []
            // These are the patrons we want to hide
            if( patron.status === false && hideInactive === true )
                returnValue.push(false)

            if( value.length === 0 )
                returnValue.push(true)

            if( patron.first_name.toLowerCase().indexOf(value.toLowerCase()) === -1 && patron.last_name.toLowerCase().indexOf(value.toLowerCase()) === -1 )
                returnValue.push(false)
            
            return !returnValue.includes(false)
        })
    }



    const handleSearch = e => {
        let setter = e.target.value
        setSearchValue(setter)
        setFilteredPatrons(filterPatrons(props.patrons, setter, hideInactiveStatus))
    }


    const handleToggleInactive = () => {
        let setter = !hideInactiveStatus
        setHideInactive(setter)
        setFilteredPatrons(filterPatrons(props.patrons, searchValue, setter))
    } 

    return (
        <div className="patronList mb-5">
            <Row className="mb-3">
                <Col md={8}>
                    <input name="search" className="form-control" onChange={handleSearch} value={searchValue} />
                </Col>
                <Col md={4}>
                    <input type="checkbox" checked={hideInactiveStatus} onChange={handleToggleInactive} /> <label>Hide Inactive</label>
                </Col>
            </Row>

            <p>{filteredPatrons.length} Patrons</p>
            
            {
                filteredPatrons !== null && filteredPatrons.length > 0 ?
                    <ul className="list-group">
                    {
                        filteredPatrons.map( (patron, index) => {
                            return (
                                <li key={`patronlist${index}`} className="list-group-item list-group-item-light">
                                    <Badge variant={patron.status ? 'success' : 'warning'}>{patron.status ? 'active' : 'inactive' }</Badge> <span>{patron.first_name} {patron.last_name}</span> 
                                    <Button onClick={() => props.handleEditPatron(patron)} className="float-right" size="sm" variant={props.currentlyEditing !== null && props.currentlyEditing.player_id === patron.player_id ? 'dark' : 'primary' }>
                                        { props.currentlyEditing !== null && props.currentlyEditing.player_id === patron.player_id ? 'Close' : 'Edit' }
                                    </Button>
                                </li>
                            )
                        })
                    }
                    </ul>

                : null
            }
        </div>
    )
}

export default PatronList
