import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

const BirthdaySubmissionForm = (props) => {

    const [ submissionData, setSubmissionData ] = useState(props.data)

    const handleChange = (e) => {
        setSubmissionData(Object.assign({}, submissionData, {
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div className="submissionForm mb-5">
            <h2>Birthday</h2>
            <Row>
                <Col md={12}>

                    <div className="form-group mb-5">
                        <label htmlFor="Happy Birthday Message">Happy Birthday Message</label>
                        <textarea className="form-control larger" onChange={handleChange} name="text1" value={submissionData.text1} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 1">Offer 1</label>
                        <textarea className="form-control" onChange={handleChange} name="text2" value={submissionData.text2} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 2">Offer 2</label>
                        <textarea className="form-control" onChange={handleChange} name="text3" value={submissionData.text3} />
                    </div>

                    <Button onClick={() => props.handleSave(submissionData)}>Save</Button>

                </Col>
            </Row>
        </div>
    )
}

export default BirthdaySubmissionForm
