import React, { useState } from 'react'
import PatronForm from './PatronForm'
import { savePatron } from '../../../services/apiService'
import { show } from '../../../services/showMessage'
import moment from 'moment'
import { validateAddPatron } from '../../../services/validationService'

const CreatePatron = (props) => {

    const defaultFields = {
        first_name: '',
        last_name:  '',
        birthday:   '',
        email:      '',
        mobile:     '',
        address:    '',
        suburb:     '',
        state:      '',
        postcode:   '',
        status:     true,
        mail_okay:  true
    }

    const [ storedData, setStoredData ] = useState(defaultFields)


    const handleSavePatron = (data) => {


        let validation = validateAddPatron(data)
        if( validation.length > 0 ){
            //console.log(validation)
            show('Please check the data you entered')
            return false
        }



        savePatron(Object.assign({}, data, {
            birthday: moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
        })).then( response => {
            // Display notification to the user
            show('Patron Details Added')
            // Clear form,
            setStoredData(defaultFields)
            // Update list of patrons
            props.getPatronList()
        })
    }


    const handleChange = (e) => {
        setStoredData(Object.assign({}, storedData, {
            [e.target.name]: e.target.value
        }))
    }

    const handleBooleanChange = (e) => {
        setStoredData(Object.assign({}, storedData, {
            [e.target.name]: !storedData[e.target.name]
        }))
    }


    return (
        <div className="createSubmission">
            <h4>Create Patron</h4>
            <PatronForm 
                handleSavePatron={handleSavePatron} 
                handleChange={handleChange}
                handleBooleanChange={handleBooleanChange}
                data={storedData} 
            />
        </div>
    )
}

export default CreatePatron
