import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import MaskedInput from 'react-maskedinput'

const MailoutSubmissionForm = (props) => {

    const [ submissionData, setSubmissionData ] = useState(Object.assign({}, props.data, {
        date:       moment(props.data.date,       'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY'),
        start_date: moment(props.data.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY'),
        end_date:   moment(props.data.end_date,   'YYYY-MM-DDTHH:mm:ss.000Z').format('DD/MM/YYYY')
    }))

    const handleChange = (e) => {
        setSubmissionData(Object.assign({}, submissionData, {
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div className="submissionForm mb-5">
            <Row>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="month">Month</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="date" value={submissionData.date} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="startDate">Start Date</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="start_date" value={submissionData.start_date} />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group mb-5">
                        <label htmlFor="endDate">End Date</label>
                        <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" className="form-control" onChange={handleChange} name="end_date" value={submissionData.end_date} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>

                    <div className="form-group mb-5">
                        <label htmlFor="Intro Page">Intro Page</label>
                        <textarea className="form-control larger" onChange={handleChange} name="text1" value={submissionData.text1} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 1">Offer 1</label>
                        <textarea className="form-control" onChange={handleChange} name="text2" value={submissionData.text2} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 2">Offer 2</label>
                        <textarea className="form-control" onChange={handleChange} name="text3" value={submissionData.text3} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 3">Offer 3</label>
                        <textarea className="form-control" onChange={handleChange} name="text4" value={submissionData.text4} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Offer 4">Offer 4</label>
                        <textarea className="form-control" onChange={handleChange} name="text5" value={submissionData.text5} />
                    </div>


                    <div className="form-group mb-5">
                        <label htmlFor="Venue Text">Venue Designated Text</label>
                        <textarea className="form-control" onChange={handleChange} name="text6" value={submissionData.text6} />
                    </div>
                    
                    
                    <Button onClick={() => props.handleSave(submissionData)}>Save</Button>
                    
                </Col>
            </Row>
        </div>
    )
}

export default MailoutSubmissionForm
