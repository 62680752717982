import React from 'react'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'toasted-notes/src/styles.css'

import routes from '../../navigation/routes'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom"
import PrivateRoute from '../utilities/PrivateRoute'

function App() {
  	return (
		<Router>
			<Switch>
				{
					routes.map( ( route, index ) => {
						if( !route.private ) 
							return <Route exact={route.exact} path={route.path} component={route.component} key={index} />
						else
							return <PrivateRoute exact={route.exact} path={route.path} component={route.component} key={index} />
					})
				}
            </Switch>
		</Router>
	)
}

export default App;
